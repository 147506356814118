import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const TermsConditions = ({ data }) => {
  return (
    <section id="terms-conditions">
      <Container>
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TermsConditions
