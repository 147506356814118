import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TermsConditions from "../components/terms-conditions/index"

const TermsPage = ({ data }) => {
  const pageData = data.allContentfulTermsAndConditions.nodes[0]

  return (
    <Layout>
      <SEO
        title="Terms and Conditions"
        description={pageData.metaDescription}
      />
      <TermsConditions
        data={
          pageData.childContentfulTermsAndConditionsContentRichTextNode
            .childContentfulRichText.html
        }
      />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulTermsAndConditions {
      nodes {
        id
        title
        metaDescription
        childContentfulTermsAndConditionsContentRichTextNode {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`

export default TermsPage
